import { useState, useEffect } from 'react';
import Login from "../Pages/Login/indes";
import Lottie from 'react-lottie';
import Block from './../Images/block.json';

function ContrutorLogin() {

    const [Ip, setIP] = useState('187.0.54.235');
    const [Cidade, setCidade] = useState('Sao Sebastiao do Paraiso');
    const [Estado, setEstado] = useState('Minas Gerais');
    const [Pais, setPais] = useState('Brazil');
    const [Autorizado, setAutorizado] = useState(null);

    useEffect(() => {
                (async () => {
                    await fetch('https://geolocation-db.com/json/', {
                        method: 'GET',
                    }).then((response) => response.json())
                        .then((e) => {
                            const { IPv4, city, state, country_name } = e;

                            if (IPv4 === Ip && city === Cidade && state === Estado && country_name === Pais) {
                                setAutorizado(true);
                            } else {
                                setAutorizado(false);
                            };

             

                        });
                })();
    }, []);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: Block,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    if (Autorizado) {
        return (
            <Login />
        );
    } else {
        return (
            <div style={{ position: 'absolute', backgroundColor: "#1A2B52", width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', overflow: 'hidden' }}>
                <div style={{ backgroundColor: '#fff', borderRadius: 10, flexDirection: 'column', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Lottie
                        options={defaultOptions}
                        height={400}
                        width={400}
                    />
                    <h1 style={{ position: 'absolute', marginTop: 300, color: "#1A2B52" }}>{Autorizado === null ? "Verificando" : Autorizado === null ? "Autorizado" : "Sem Autorização"}</h1>
                </div>
            </div>
        );
    };

};

export default ContrutorLogin;
