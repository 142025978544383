import { Space, Button, Select } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import Logo from './../../Images/logo.png';
import LoadingPage from '../../Components/Loading';
import Funcoes from './funcoes';
import './login.css';

function Login() {

    const { Load, Logar, Usuario, setUsuario, Senha, setSenha, Security, setSecurity } = Funcoes();

    return (
        <div className='Container'>
            <div className='From'>

                <div className='ContainerLogo'>
                    <img className='Logo' src={Logo} />
                </div>

                <Space style={{ flexDirection: 'column', marginBottom: 25 }}>

                    <div style={{ alignItems: 'end', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <select value={Usuario} onChange={(e) => setUsuario(e.target.value)} className='Select' placeholder="Usuário" >
                            <option style={{fontSize:25}} value="">Selecione seu usuário</option>
                            <option style={{fontSize:25}}>caio</option>
                            <option style={{fontSize:25}}>sandro</option>
                        </select>
                    </div>

                    <div style={{ alignItems: 'end', justifyContent: 'center', display: 'flex', flexDirection: 'column' }}>
                        <input value={Senha} onChange={(e) => setSenha(e.target.value)} className={Security ? 'Input' : 'Input Senha'} placeholder="Senha" />
                        <Button onClick={() => setSecurity(!Security)} style={{ position: 'absolute', marginRight: 10, backgroundColor: '#2d800c' }} type="primary" shape="circle" icon={Security ? <EyeOutlined /> : <EyeInvisibleOutlined />} />
                    </div>

                </Space>

                <Button onClick={Logar} type="primary" className='BtnLogar'>Logar</Button>

            </div>

            {Load && <LoadingPage />}

        </div>
    );
}

export default Login;
